#services {
    background-color: #9ddfef;
    color: rgb(48, 48, 48);
    padding: 5rem;
}

div.card {
    background-color: #17a2b8;
    width: 100%;
    margin: 10px;
    padding: 5px;
}
