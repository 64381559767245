.jumbotron{
    /*background: url("./assets/img/jumbox_background.jpg") center center / cover;*/
    color: #fff;
    margin-top: 61px;
    position: relative;
    z-index: 1;
}

.jumbotron::before {
    background: url("./assets/img/jumbox_background.jpg") center center / cover no-repeat;
    content: "";
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%; 
    height: 100%;  
    opacity: .50;
    z-index: -1;

}

.jumbotron img,
.jumbotron p {
    display:block;
    margin-left:auto;
    margin-right:auto;
    text-align: center;
}